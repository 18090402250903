@import "styles/_index.scss";

.primary {
  width: $button-width;
  height: $input-height;
  background-color: $primary-color;
  color: white;
  height: 48px;
  &:hover,
  &:focus,
  &:active {
    background-color: $primary-color;
    outline: none;
    border: none;
    color: white;
  }

  &:disabled {
    background-color: $disable-color;
    color: $form-color;
  }
}

.default {
  width: $button-width;
  height: $input-height;
  border: 1px solid $base-text-color;
}
