@import "./custom";
@import "./variables";
@import "./mixin";

.table {
  .ant-table-thead > tr > th {
    // background-color: $header-table-color;
    padding: 3.5px 16px;
    color: $primary-color;
  }
  .ant-table-tbody > tr > td {
    padding: 14px 16px;
    // color: $text-table-color;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
    display: none;
  }
  .ant-pagination-item-active {
    background-color: $primary-color !important;
  }
  .ant-pagination-item-active a {
    // color: $white-color;
    font-weight: bold;
  }
  .ant-pagination-item-link,
  .ant-pagination-item {
    border: none;
    background-color: #eeeeee;
    border-radius: 4px;
  }
}

.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.align-baseline {
  align-items: baseline;
}

.align-end {
  align-items: flex-end;
}

.modal {
  .ant-modal-body {
    padding: 21px;
  }
  .ant-form-item-label > label {
    flex-direction: row-reverse;
    font-size: 13px;
    font-weight: 700;
  }
  .ant-form-item-label {
    padding-bottom: 10px;
  }
  .ant-input {
    min-height: 40px;
    border-radius: 5px;
  }
  .ant-input-affix-wrapper {
    border-radius: 5px;
    padding: 0px 11px;
  }
  .ant-picker {
    height: 40px;
    border-radius: 5px;
  }

  .ant-select-selector {
    min-height: 40px !important;
    border-radius: 5px !important;
  }
  .ant-select-multiple .ant-select-selection-item {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .ant-select-multiple .ant-select-selection-item-remove > .anticon {
    background: rgba(0, 0, 0, 0.6);
    border-radius: 99px;
    padding: 2px;
    color: white;
  }
  &__title {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 28px;
  }
}

.form {
  .ant-form-item-label > label {
    flex-direction: row-reverse;
    font-size: 13px;
    font-weight: 700;
  }
  .ant-form-item-label {
    padding-bottom: 10px;
  }
  .ant-input {
    height: 40px;
    border-radius: 5px;
  }
  .ant-input-affix-wrapper {
    border-radius: 5px;
    padding: 0px 11px;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-picker {
    height: 40px;
    border-radius: 5px;
  }
  .ant-select-selector {
    min-height: 40px !important;
    border-radius: 5px !important;
    display: flex;
    align-items: center;
  }
  .ant-select-multiple .ant-select-selection-item {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    border: 1px solid rgba(0, 0, 0, 0.12);
  }
  .ant-select-multiple .ant-select-selection-item-remove > .anticon {
    background: rgba(0, 0, 0, 0.6);
    border-radius: 99px;
    padding: 2px;
    color: white;
  }
}

.btn-modal {
  width: 224px;
  height: 55px;
  border-radius: 43px;
  font-size: 16px;
  font-weight: 700;
  &--cancel {
    border: 2px solid #000000;
  }
}

.spin {
  padding: 30px 50px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  height: 100%;
  min-height: calc(100vh - #{$header-height});

  display: flex;
  justify-content: center;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.btn-detail {
  height: 36px;
  border-radius: 5px;
  font-weight: 700;
  padding: 7px 20px;
  &--delete {
    background-color: #a61f17;
    // color: $white-color;
    border: 1px solid #a61f17;

    &:hover,
    &:visited,
    &:focus {
      background: #a61f17;
      color: #fff;
      border: 1px solid #a61f17;
    }
  }
}

.select {
  height: 36px;
  min-width: 114px;
  .ant-select-selector {
    display: flex;
    align-items: center;
    height: 36px !important;
    border-radius: 4px !important;
  }
  .ant-select-selection-placeholder {
    font-size: 12px;
  }
}

.select-100 {
  height: 36px;
  .ant-select-selector {
    display: flex;
    align-items: center;
    height: 36px !important;
    border-radius: 4px !important;
  }
  .ant-select-selection-placeholder {
    font-size: 12px;
  }
}

.select-menu {
  height: 35px;
  .ant-select-selector {
    display: flex;
    align-items: center;
    width: 284px !important;
    height: 35px !important;
    border-radius: 4px !important;
  }
  .ant-select-selection-placeholder {
    font-size: 12px;
  }
}
.title-primary {
  color: $primary-color;
  font-size: 28px;
  font-weight: 700;
}

.title-secondary {
  color: #a29490;
  font-size: 28px;
  font-weight: 700;
}

.range-picker {
  width: 252px;
  height: 36px;
  border-radius: 4px;
}

.ant-modal-wrap {
  &::-webkit-scrollbar {
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: #b8b8b8;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #b8b8b8;
  }
}

.none {
  display: none;
}

.text-error {
  font-size: 14px;
  line-height: 1.5715;
  color: #ff4d4f;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.input-number {
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
}

.width-100 {
  width: 100%;
}

.width-fit-content {
  width: fit-content !important;
}

.text-primary {
  color: $primary-color;
}

.formItem {
  :global {
    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
      border-color: $attention-color;
      background-color: $secondary-color;
    }

    .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(
        .ant-input-affix-wrapper-borderless
      ).ant-input-affix-wrapper {
      border-color: $attention-color;
      background-color: $secondary-color !important;

      input.ant-input {
        background-color: $secondary-color !important;
      }
    }

    .ant-form-item-explain-error {
      background-color: $attention-color;
      color: white;
      border-radius: 4px;
      width: fit-content;
      padding-right: 5px;
    }

    .ant-input-affix-wrapper > input.ant-input {
      background-color: white;
    }
  }
}

.text-12 {
  font-size: $font-12;
}

.default-btn {
  width: $button-width;
  height: $input-height;
  border: 1px solid $base-text-color;
}
