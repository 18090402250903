@import "styles/_index.scss";
.footer {
  height: $footer-height;
  background-color: $footer-color;
  padding: 0px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.leftText {
  a {
    color: $sub-text-color;
  }
  font-size: $font-12;
}

.rightText {
  color: $sub-text-color;
  font-size: $font-12;
}
