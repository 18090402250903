@import "styles/_index.scss";

.pageWrapper {
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
}

.body {
  flex: 1;
  background-color: $background-grey-color;
}

.footer {
  height: $footer-height;
}
