@import "styles/index";

.headerWrapper {
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: $header-height;
  background-color: $background-white-color;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  .headerLeft {
    display: flex;

    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 20px;
      border-right: 1px solid $line-color;
    }

    .menuHeader {
      display: flex;
      :global {
        .ant-menu-item {
          display: flex;
          align-items: center;
        }

        .ant-menu-item-selected {
          background-color: $primary-light-color;
          color: $base-text-color;
          &::after {
            display: none;
          }
        }

        .ant-menu-item-active {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

.headerRight {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  border-left: 1px solid $line-color;
  margin: 16px 0px;
  cursor: pointer;

  .avatar {
    margin-right: 16px;
  }

  .rightText {
    .primaryText {
      font-size: $font-12;
      color: $base-text-color;
    }

    .subText {
      color: $form-color;
      font-size: $font-12;
    }
  }
}

.antModal {
  :global {
    .ant-modal-body {
      padding: 0px;
    }
  }
}

.modalHeaderWrapper {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 24px;
  padding-bottom: 0px;
}
.modalHeader {
  font-size: 14px;
  color: $primary-color;
  cursor: pointer;
  span {
    margin-left: 5px;
  }
}

.modalBody {
  padding: 0px 70px;

  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .rowTitle {
    font-weight: bold;
    font-size: 14px;
  }

  .rowValue {
    font-size: 14px;
  }

  .note {
    margin-top: 40px;
    font-size: 14px;
    display: flex;

    .text {
      font-size: 14px;
    }

    .phoneNumber {
      font-size: 14px;
      color: $primary-color;
      margin-top: 5px;
    }
  }
}
