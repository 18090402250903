$header-height: 60px;
$side-nav-width: 220px;
$footer-height: 72px;
$input-height: 48px;
$button-width: 160px;

// Color
$primary-color: #00aec4;
$link-color: #0062b1;
$primary-light-color: #ebf9fb;
$secondary-color: #ffedbe;
$background-white-color: #ffffff;
$attention-color: #ee8f00;
$notice-color: #de4335;
$base-text-color: #222222;
$sub-text-color: #575a5a;
$form-color: #a6a6a6;
$disable-color: #cccccc;
$table-color: #d5d5d5;
$line-color: #e5e5e5;
$footer-color: #ececec;
$background-grey-color: #f6f6f6;
$background-input: #fbfbfb;

// Typography
$font-10: 10px;
$font-12: 12px;
$font-14: 14px;
$font-16: 16px;
$font-20: 20px;
$font-24: 24px;
