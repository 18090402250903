@import "styles/variables.scss";

.pageContent {
  // height: calc(100vh - $header-height - $footer-height);
  overflow: auto;
  margin-top: $header-height;

  &::-webkit-scrollbar {
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: content-box;
    background-color: #b8b8b8;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #b8b8b8;
  }
}
